<template>
  <b-card-code title="">
    <div class="row mb-2">
      <div class="col-lg-6">
        <h4>Árajánlati lista</h4>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.name }}</span>
        </div>

        <div v-else-if="props.column.field === 'is_project'">
          {{ props.row.is_project }}
        </div>


        <!-- Column: Status -->
        <!--<span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>-->

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <!--<b-dropdown-item v-b-modal.changeStatusModal @click="changeStatus(props.row.id)">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                <span>Státusz állítás</span>
              </b-dropdown-item>-->
              <!--<b-dropdown-item @click="show(props.row.id)">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Feladatok</span>
              </b-dropdown-item>-->
              <!--<b-dropdown-item @click="document(props.row.id)">
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50"
                />
                <span>Dokumentum generálás</span>
              </b-dropdown-item>-->

              <b-dropdown-item v-if="props.row.type !== 'project'" @click="editable_project = props.row;$root.$bvModal.show('offer_edit')">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Szerkesztés</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="props.row.type !== 'project'" @click="$router.push('/projects/create/full/'+props.row.id)">
                <feather-icon
                  icon="PlusCircleIcon"
                  class="mr-50"
                />
                <span>Projekt létrehozása</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="props.row.type !== 'task'" @click="$router.push('/project/list/invoice/'+props.row.id)">
                <feather-icon
                  icon="FileIcon"
                  class="mr-50"
                />
                <span>Számlák</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="props.row.type !== 'task'" @click="editProject(props.row.id)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Szerkesztés</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal id="offer_edit" ref="offer_edit" hide-footer title="Ajánlat módosítása">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Ajánlat megnevezése"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="editable_project.name"
              type="text"
              placeholder="Ajánlat megnevezése"
              class="mb-2"
            />
          </b-form-group>
          <b-form-group
            label="Megrendelő"
            label-for="customer"
          >
            <b-form-input
              id="customer"
              v-model="editable_project.customer"
              type="text"
              placeholder="Megrendelő"
              class="mb-2"
            />
          </b-form-group>
          <b-form-group
            label="Kezdés dátuma"
            label-for="start_date"
          >
            <b-form-input
              id="start_date"
              v-model="editable_project.start_date"
              type="date"
              placeholder="Kezdés dátuma"
              class="mb-2"
            />
          </b-form-group>
          <b-form-group
            label="Cég"
            label-for="company"
          >
            <b-form-select
              id="company"
              v-model="editable_project.company"
              :options="companies"
              placeholder="Kezdés dátuma"
              class="mb-2"
            />
          </b-form-group>
        </b-col>
        <b-col md="12" style="justify-content: space-between;display:flex">
          <button @click="$root.$bvModal.hide('offer_edit')" class="btn btn-danger">Mégsem</button>
          <button @click="editOffer()" class="btn btn-primary">Szerkesztés</button>
        </b-col>
      </b-row>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormSelect, BDropdown, BDropdownItem, BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
  },
  data() {
    return {
      companies: ['Monolit Épszer Kft.', 'Monolit Profi Kft.'],
      editable_project: {},
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Munkaszám',
          field: 'work_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés munkaszám',
          },
        },
        {
          label: 'Projekt neve',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Megrendelő',
          field: 'customer',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés megrendelők között',
          },
        },
        {
          label: 'Kezdés dátuma',
          field: 'start_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés határidőre',
          },
        },
        {
          label: 'Cég',
          field: 'company',
          tdClass: 'text-right',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés összegre',
          },
        },
        {
          label: 'Előkészítő',
          field: 'preparatory_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés Építés vezetőre',
          },
        },
        {
          label: 'Projekt lett?',
          field: 'is_project',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-info',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-success',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getProjects()
  },
  methods: {
    editOffer() {
      axios({
        method: 'put',
        url: `project/offer/${this.editable_project.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: this.editable_project,
      }).then(() => {
        this.getProjects()
        this.$root.$bvModal.hide('offer_edit')
        this.editable_project = {}
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen szerkesztve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    checkRole() {
      return JSON.parse(localStorage.getItem('user')).role.includes('documents_list')
    },
    arachive(id) {
      axios({
        method: 'put',
        url: `project/archive/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getProjects()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen archiválva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    editProject(id) {
      this.$router.push({ name: 'monolit.projects.edit', params: { id } })
    },
    getProjects() {
      axios({
        method: 'get',
        url: 'project/offer/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.rows = response.data
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
